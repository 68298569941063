@import './variables.scss';
@import './colors.scss';
@import '~@progress/kendo-theme-material/dist/all.scss';
@import './default.scss';
@import './buttons.scss';
@import './modal.scss';


body {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(18, 38, 63, 0);
  background-color: $body-bg;
  color: $body-color;
  font-family: $body-font-family;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  line-height: $body-line-height;
  margin: 0;
}

.tagging-editor > canvas {
  left: 0;
}



.prevent-click > *{
  pointer-events: none;
}

.no-radius-left {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.dropdown-menu {
  background-clip: padding-box;
  background-color: $white;
  border: 1px solid $separator-primary;
  border-radius: 0.375rem;
  color: $action-buttons-text;
  font-size: 0.9375rem;
  list-style: none;
  margin: 0;
  min-width: 10rem;
  padding: 0.5rem 0;
  text-align: left;
  z-index: 10001;
  -webkit-animation: dropdownMenu 0.15s;
  animation: dropdownMenu 0.15s;
  box-shadow: 3px 8px 15px 0 rgba(0, 0, 0, 0.30);
  @-webkit-keyframes dropdownMenu {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes dropdownMenu {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}


.select2-container{
  z-index: 999999;
}
.k-button-solid-base{
  box-shadow: none;
}

.k-dialog,.k-dialog-content{
  border: none;
  background: transparent;
  box-shadow: none;
}

.template-buttons{
  text-align: left;
  color: $white;
  > .buttons-group{
    padding: 0.75rem 0.75rem 0 0.75rem;
    border-bottom: 1px solid $separator-secondary;
  }
  > .no-smarttag-text{
    padding: 0.75rem 0.75rem 0 0.75rem;
  }

}
.template-buttons > .buttons-group > button {
  margin-right: 0.75rem;
  margin-bottom: 0.75rem;
}

.classifier-pages-wrapper{
  padding: 0.75rem;
  color: $white;
  > h3 {
    text-align: left;
    font-weight: 400;
    font-size: 0.938rem;
    text-transform: capitalize;
    margin-bottom: 0.938rem;
  }
}
.classifier-page-buttons{
  text-align: left;
  font-size: 0.75rem;
  > button {
    margin-right: 0.75rem;
    margin-bottom: 0.75rem;
  }
}

.main-content{
  background: $main-bg;
}
.viewer-wrapper{
  background: $viewer-bg;
  border-radius: $kendo-border-radius;
  .card-header{
    border: none;
    &:first-child{
      border-bottom: 1px solid $separator-primary;
    }
  }
}

.toolbar-search {
  input {
    background: #E5E5E5;
    font-size: 0.75rem;
    border: 1px solid #ced4da;
    line-height: normal;
    box-sizing: border-box;
    color: $black;
  }
  .k-animation-container{
    right: 0;
    left: auto!important;
  }
}

.form-control:focus {
  box-shadow: none;
  outline: 0;
}

.input-group > .form-control,
.input-group > .form-select {
  flex: 1 1 auto;
  min-width: 0;
  position: relative;
  width: 1%;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}


.page-number-input{
  height: 35px;
  width: 35px;
  border-radius: $kendo-border-radius;
  background-color: #e5e5e5;
  font-size: 0.75rem;
  border: none;
  text-align: center;
  outline: none;
}
.page-number-input + span.label{
  font-size: 0.75rem;
}

/********** Range Input Styles **********/
/*Range Reset*/
input[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 7rem;
}

/* Removes default focus */
input[type='range']:focus {
  outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type='range']::-webkit-slider-runnable-track {
  background-color: $range-slider-track;
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -4px; /* Centers thumb on the track */

  /*custom styles*/
  background-color: $range-slider-thumb;
  height: 1rem;
  width: 1rem;
  border-radius: 0.5rem;
}

input[type='range']:focus::-webkit-slider-thumb {
  border: 1px solid $range-slider-thumb;
  outline: 3px solid $range-slider-thumb;
  outline-offset: 0.125rem;
  opacity: 0.75;
}

/******** Firefox styles ********/
/* slider track */
input[type='range']::-moz-range-track {
  background-color: $range-slider-track;
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type='range']::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  border-radius: 0; /*Removes default border-radius that FF applies*/

  /*custom styles*/
  background-color: $range-slider-thumb;
  height: 2rem;
  width: 1rem;
}

input[type='range']:focus::-moz-range-thumb {
  border: 1px solid $range-slider-track;
  outline: 3px solid $range-slider-track;
  outline-offset: 0.125rem;
  opacity: 0.75;
}

#document-tools{
  justify-content: center;
}

.card {
  word-wrap: break-word;
  background-clip: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
}
.card-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  min-height: 3.75rem;
  padding: 0.75rem;
  &:first-child{
    border-bottom: 1px solid $separator-secondary;

  }
  > :first-child {
    flex:1;
  }
}

.input-group {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.fields-block{
  background-color: $split-pane-bg;
  border-radius: $kendo-border-radius;
}

.k-window-titlebar-actions{
  .k-svg-icon > svg{
    fill:$black;
  }
}

.search-dropdown{
  max-width: 350px;
  font-size: 0.75rem;
}
.search-dropdown .dropdown-item{
  border: 1px solid transparent;
  &:hover{
    border-color: $green-light;
  }
}
.classfier-field-btn {
  span.k-button-text{
    gap: 0.5rem;
  }
}
.field-btn-color {
  height: 18px;
  width: 16px;
}

.styled-buttons-group{
  display: flex;
  gap: 0.1875rem;
  flex-direction: row;
}
.styled-buttons-group > button{
  border-radius: 0;
}
.styled-buttons-group > button:first-child{
  border-top-left-radius: $kendo-border-radius;
  border-bottom-left-radius: $kendo-border-radius;
}
.styled-buttons-group > button:last-child{
  border-top-right-radius: $kendo-border-radius;
  border-bottom-right-radius: $kendo-border-radius;
}