@import '../../../themes/colors.scss';
.ReactVirtualized__Grid__innerScrollContainer {
  margin-right: 5px;
}

.field-wrapper {
  padding: 1px 0px;
}

.field-wrapper.field-group-member {
  padding: 1px 10px;
  border-left: 1px solid $gray3;
  border-right: 1px solid $gray3;
  &.field-wrapper-selected {
    border-left: 1px solid $green-light;
    border-right: 1px solid $green-light;
  }
}

.field-card {
  margin-bottom: 0.85rem;
}

.field-card-body {
  padding: 0.5rem;
  padding-top: 0;
}

.field-card-header {
  padding: 0rem 0rem;
  min-height: 2.5rem;
  border-bottom: 0;
}

.field-card-selected {
  border-color: #404142;
}

.template-field-border {
  border: 1px solid transparent !important;
}
.template-field-border:hover {
  border: 1px solid $green-light !important;
}
.template-field-body {
  border: 1px solid $gray3;
  border-radius: 12px;
}
.field-name {
  display: inline;
  line-height: 23px;
}
.template-field-action-icons {
  display: none;
}

.template-field-action:hover .template-field-action-icons {
  display: inline;
}

.template-field:hover .group-field-buttons {
  display: block;
}

.group-field-buttons {
  display: none;
}

.group-buttons {
  display: none;
}

.group-name:hover .group-buttons {
  display: block;
}

.card-header-title.field-header-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 30px) !important;
}
.field-card-header .field-header-overflow {
  width: calc(100% - 100px) !important;
}
.modal .field-card-header .field-header-overflow {
  width: calc(100%) !important;
}
/* Browse Fields End */

/* Group Fields START */
h4.field-header-text.card-group-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}
.field-card-header .groups-header-overflow {
  max-width: 66%;
}
/* Group Fields END */
.field-bg {
  background-color: $gray3 !important;
}

.field-card-selected .field-card-selected {
  background-color: $green-light !important;
}

.group-card-header {
  background-color: $gray3;
  border: 1px solid #8b8d8f;
}

.group-card-body {
  padding-bottom: 0;
  padding-top: 0.85rem;
  border: 1px solid $gray3 !important;
  border-bottom-left-radius: calc(0.5rem - 1px) calc(0.5rem - 1px);
  border-bottom-right-radius: calc(0.5rem - 1px) calc(0.5rem - 1px);
}

.field-card-selected .group-card-header {
  background-color: $green-light1 !important;
  border: 1px solid $green-light1;
}

.field-card-selected .group-card-body {
  border: 1px solid $green-light1 !important;
}

.field-card-selected .field-card-header {
  background-color: $green-light1 !important;
}

.field-selected {
  background-color: $green-light1;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.card-inactive .field-card-header {
  background-color: $gray3 !important;
}

.field-bg-new-nav {
  background-color: $red !important;
}

.template-field-card-body {
  padding-top: 0.5rem !important;
}

.table-card {
  padding-bottom: 5px;
  white-space: nowrap;
  position: relative;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.table-card .btn {
  width: 100px;
  font-size: 14px;
}

table.formula-table {
  width: 90%;
}

table.formula-table td div {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: 50px;
}

.pointer {
  cursor: pointer;
}

.modal-body,
div.edit-table .modal-footer {
  padding: 15px;
}

.card-body.text-m {
  padding-left: 2px;
}

table.sidebar-table tr td.selected {
  background-color: $gray4;
}

table.sidebar-table tr td input {
  margin-top: 1px !important;
  font-size: 12px;
}

table.sidebar-table tr td textarea {
  margin-top: 0 !important;
  padding: 5px 3px 2px 3px !important;
  font-size: 12px;
  resize: none;
  cursor: pointer;
}

table.sidebar-table tr td textarea:focus {
  cursor: text;
}

.table-card .loader {
  position: absolute;
  left: 48%;
}
.field-group-wrapper {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
  margin-bottom: 0;
}

.field-group-wrapper .field-card-header {
  border-bottom: hidden;
}

.search-document-button .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  svg {
    width: 13px;
  }
}
.link-search-document .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
