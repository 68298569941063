.page-img:hover {
  cursor: crosshair;
}
.canvas {
  position: absolute;
  z-index: 1;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.canvas:hover {
  cursor: crosshair;
}

table.table-resizable .drag-item {
  cursor: pointer;
  margin-top: -2px;
  font-size: 13px;
  font-weight: bold;
  max-height: 15px;
  max-width: 15px;
}

.up-arrow,
.down-arrow {
  position: absolute;
  color: #2525f2;
  font-size: 22px;
  opacity: 1;
}

.selected .up-arrow,
.selected .down-arrow {
  color: #2bb673;
}

.up-arrow {
  top: -23px;
}
.down-arrow {
  top: -5px;
}
