@import '../../../src/themes/colors.scss';

.sign-in {
  max-height: 100vh;
  background-color: $signin-bg;
  .sign-in-body {
    border-top: 3px solid $green2;
  }
  .sign-in-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
  }
}
