$transparent: rgba(0, 0, 0, 0);
$white: #fff;
$gray1: #4d4e4f;
$gray2: #404142;
$gray3: #8b8d8f;
$gray4: #c8cbcf5e;
$gray5: #313233;
$gray6: #c8cbcf;
$regentGray: #8796a6;
$blue-light: #00b3f0;
$blue-light1: #add8e6ff;
$blue: #2525f2;
$blue1: #193456;
$blue2: rgba(21, 46, 77, 0.5);
$black: rgba(0, 0, 0, 0.75);
$black1: rgba(0, 0, 0, 0.5);
$black2: #000000ff;
$red: #e32323;
$red1: #ff0000ff;
$green-light: #2bb673;
$green-light1: #1bc573;
$green-light2: #b5ead1;
$green-light3: #1aca77;
$green1: #2bb673;
$green2: #00a35f;
$yellow-light: #a0cf67;
$yellow1: #f6c343;
$yellow2: #f8f6b8;
$orange: #f28824;
$purple: #7c41d8;
$success: #2bb673;




$action-buttons: #E1E1FC;
$action-buttons-text: #1F1F1F;
$kendo-window-font-family: 'Questrial', sans-serif;
$kendo-button-font-family: 'Questrial', sans-serif;
$kendo-base-text: $white;
$kendo-window-text: $white;
$kendo-window-font-size:0.9375rem;
$kendo-window-line-height:1.5;
$kendo-splitter-bg: $black;
$kendo-splitbar-selected-bg:#4d4e4f;

$main-bg: $black;
$signin-bg:  #313233;
$viewer-bg: #f5f5f5;
$separator-primary: #d6d6d6;
$separator-secondary: #4d4e4f;
$range-slider-track: #d6d6d6;
$range-slider-thumb: #2525f2;
$split-pane-bg: #1f1f1f;
$text-on-dark: #7f7f7f;

