@import '../../themes/colors.scss';

.tagging-editor-state-dropdown {
  z-index: 3;
  font-size: 0.75rem;
  .k-button{
    font-size: 0.75rem;
  }
  &.show {
    .dropdown-menu.show {
      width: auto;
      height: auto;
      display: flex;
      min-width: unset;
      flex-direction: column;
      max-height: 350px;
      overflow: initial;
    }
  }

  .pixel-perfect + div{
    border-top: 1px solid $separator-primary;
  }
  .dropdown-menu.show {
    width: auto;
    padding: 0;
  }


  &.dropdown-menu {
    padding: 0;
    .dropdown-item {
      min-height: 50px;
      padding: 0.5rem 0.75rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      color: #1F1F1F;
      span.title {
        margin: 0.375rem 1.5rem;
        width: 100%;
        text-align: center;
      }
      &:active,&.active{
        background: transparent;
      }
      .k-disabled {
        background: none;
      }
      .svg-icon path{
        fill: #1F1F1F;
      }
    }
  }
  .dropdown-toggle {
    position: relative;
    line-height: 1;
    span.icon {
      position: absolute;
      top: 50%;
      left: 0;
      width: auto;
      transform: translate(50%, -50%);
    }
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }

}

.tagging-editor-state-dropdown-submenu{
  font-size: 0.75rem;
}

.dropdown-item.active, .dropdown-item:active{
  background: transparent;
}

