$kendo-border-radius: 0.375rem;
$body-bg: #000;
$body-color: #fff;
$body-font-size: 0.9375rem;
$body-font-weight: 400;
$body-line-height: 1.5;
$body-font-family: 'Questrial', sans-serif;
$kendo-splitter-font-family: 'Questrial', sans-serif;
$kendo-popover-font-family: 'Questrial', sans-serif;
$body-text-align: left;
