@import '../../themes/colors';

/* by Rouben Meschian - rmeschian@gmail.com */

table.annotationTable {
  position: absolute;
  table-layout: fixed;
  box-sizing: border-box;
  z-index: 3000;
  border: none;
  padding: 0;
  margin: 0;
}

table.annotationTable th,
table.annotationTable td {
  border: none;
  position: relative;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

table.annotationTable th {
  height: 0;
}

table.annotationTable tr th:first-child,
table.annotationTable tr td:first-child {
  width: 0 !important;
}

table.annotationTable tr td:first-child,
table.annotationTable tr th:first-child {
  width: 0;
  padding: 0;
  border: none;
}

/* COL/ROW ADDERS */
table.annotationTable .columnAdder {
  cursor: pointer;
  z-index: 4000;
  height: 16px;
  position: absolute;
  bottom: 0;
  left: -1px;
  right: -1px;
}

table.annotationTable .rowAdder {
  cursor: pointer;
  z-index: 4000;
  width: 16px;
  position: absolute;
  bottom: -1px;
  top: -1px;
  right: 0;

  border-right: solid 1px $blue;
}

/* DELETER --------------------------- */
.tableDeleteBtn {
  position: absolute;
  right: -30px;
  top: -30px;
  width: 10px;
  height: 10px;
  color: $green-light;
  z-index: 4000;
  font-size: 20px;
  /* background-color: red; */
  cursor: pointer;
  visibility: hidden;
}

table.annotationTable.annotationTableSelected .tableDeleteBtn {
  visibility: visible;
}

/* MOVER ----------------------------- */
.tableMoveHandle {
  position: absolute;
  left: -30px;
  top: -30px;
  width: 10px;
  height: 10px;
  color: $green-light;
  z-index: 4000;
  cursor: move;
  visibility: hidden;
}

table.annotationTable.annotationTableSelected .tableMoveHandle {
  visibility: visible;
}

/* RESIZERS -------------------------- */

table.annotationTable .DragHandle {
  transform: none !important;
}

table.annotationTable .tableResizer {
  color: $blue;
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: 4001;
  font-size: 19px;
  visibility: hidden;
}

table.annotationTable.annotationTableSelected .tableResizer {
  color: $green-light;
  visibility: visible;
}

table.annotationTable .tableResizer.verticalTableResizer {
  cursor: col-resize;
  right: -3px;
  top: -19px;
}

table.annotationTable .tableResizer.horizontalTableResizer {
  cursor: row-resize;
  left: -14px;
  top: -18px;
}

.DragHandleActive {
  color: $red1 !important;
}

/* MERGE HANDLES */

.mergeRowHandle {
  z-index: 4000;
  position: absolute;
  right: 0;
  left: 0;
  bottom: -1px;
  height: 3px;
  cursor: pointer;
}

.mergeColHandle {
  z-index: 4000;
  position: absolute;
  right: -1px;
  top: 0;
  bottom: 0;
  width: 3px;
  cursor: pointer;
}

/* BORDERS */

table.annotationTable .bottomBorder {
  border-bottom: $blue solid 1px;
}
table.annotationTable .rightBorder {
  border-right: $blue solid 1px;
}

table.annotationTable.annotationTableSelected .bottomBorder {
  border-bottom: $green-light solid 1px;
  bottom: -1px;
}
table.annotationTable.annotationTableSelected .rightBorder {
  border-right: $green-light solid 1px;
  right: -1px;
}

table.annotationTable.annotationTableSelected .rightBorder.activeBorder {
  border-color: $red1;
}
