.classification-field-select2 .select2-selection__arrow {
  display: none;
}

.select2-container--open .select2-dropdown--above,
.select2-container--open .select2-dropdown--below {
  display: inline-table !important;
  min-width: max-content;
}

.overflow-hidden {
  overflow: hidden;
}

.classification-field-select2 .select2-container--default .select2-selection--single {
  border: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.classification-field-select2 .select2-container--default .select2-container--focus .select2-selection--multiple,
.classification-field-select2 .select2-container--default .select2-selection--multiple {
  height: auto !important;
  width: calc(100% - 10px) !important;
  min-height: calc(2.40625rem + 2px) !important;
}

.classification-field-select2 .select2-container--default {
  width: 97% !important;
}

.classification-field-select2 .form-control-sm + .select2-container .select2-selection--single,
.classification-field-select2
  .form-control-sm
  + .select2-container--default
  .select2-container--focus
  .select2-selection--multiple,
.classification-field-select2 .form-control-sm + .select2-container--default .select2-selection--multiple {
  font-size: 0.8125rem;
  line-height: 1.75;

  height: calc(1.67188rem + 2px);
  display: flex !important;
  align-items: center;
}
