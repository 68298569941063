.svg-icon {
  &.svg-icon-sm {
    width: 1rem;
  }

  &.svg-icon-md {
    width: 1.5rem;
  }

  &.svg-icon-lg {
    width: 2rem;
  }

  &.svg-icon-auto-size {
    width: auto;
  }

  padding: 0;
  path {
    fill: white;
    stroke-width: 1;
    stroke: transparent;
  }

  &.primary-color {
    path {
      fill: #2525f2;
    }
  }

  &.success-color {
    path {
      fill: #2bb673;
    }
  }

  &.danger-color {
    path {
      fill: #dc3545;
    }
  }

  &.dark-color {
    path {
      fill: #000000;
    }
  }
}
