@import '../../themes/colors';
.microtask-validation-modal-container {
  .formula-list {
    .formula-list-item {
      margin-bottom: 0.5rem;
    }
    .formula-list-item:last-child {
      margin-bottom: 0;
    }
    .formula-label {
      color: $gray6;
    }
  }
}
