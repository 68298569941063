@import '../../themes/colors';

.smart-tag,
.smart-tag-new-nav {
  z-index: 2 !important;
  border-color: $blue !important;
  border-style: solid;
  border-width: 1px;
  background: transparent;
  &.selected {
    background: #1aca77 !important;
    opacity: 0.3;
  }
}

.smart-tags-to-back .smart-tag,
.smart-tags-to-back .smart-tag-new-nav {
  z-index: 10000 !important;
}

.smart-tag-origin-edit {
  cursor: pointer;
  z-index: 100001;

  position: absolute;
  top: -15px;
  left: 100%;

  transform: translate(-100%, -100%);
  padding: 0;

  span {
    color: $blue;
  }
}

//.smart-tag-origin-edit.save-cancel-buttons {
//  &.table-mode {
//    transform: translate(-100%, -200%);
//  }
//}

.selectable-tag {
  border-color: $blue;
  opacity: 0.5;
  border-style: solid;
  border-width: 1px;
  position: absolute !important;
  cursor: pointer;
  z-index: 9999;
}
