@import '../../themes/colors.scss';

.rcw-conversation-container > .rcw-header {
  background-color: $gray2;
  height: 50px;
  .rcw-title {
    font-size: 18px;
    padding: 0;
  }
}

.rcw-message > .rcw-response {
  color: $gray5;
  .rcw-message-text {
    background-color: $blue;
    color: $white;
  }
}
.rcw-client .rcw-message-text {
  background-color: $gray3;
}
.rcw-send {
  height: 40px;
  width: 40px;
  img {
    display: none;
  }
  background-image: url(./img/send-message.svg);
}

.rcw-new-message {
  color: $gray5;
  width: 19.5rem;
  height: 2.5rem;
}

.rcw-picker-btn {
  display: none;
}

.rcw-launcher {
  background-color: $blue;
}
.rcw-input {
  cursor: text;
}

.loader-container {
  background-color: $blue;
  .loader-dots {
    background: $white;
  }
}
.rcw-widget-container {
  margin: 0 40px 40px 0;
}
