@import '../../../themes/colors';

.search-highlight {
  background-color: $green1 !important;
  border: solid 1px gray;
  opacity: 0.5;
  position: absolute;
}

.search-item {
  border-color: $green1;
  background: $yellow1;
  opacity: 0.2;
  border-style: solid;
  border-width: 1px;
  position: absolute;
  cursor: pointer;
}

.label-item {
  border-color: $yellow-light;
  background: $green1;
  opacity: 0.2;
  border-style: solid;
  border-width: 1px;
  position: absolute;
  cursor: pointer;
}

.dropdown-menu {
  &.show {
    position: absolute;
    transform: translate3d(362px, 31px, 0px);
    top: 0px;
    left: 0px;
    will-change: transform;
    background: $gray2;
  }
  .dropdown-menu-container {
    max-height: 325px;
    max-width: 500px;
    min-width: 300px;
    overflow: auto;
  }
}

.is-disabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
}
