
.gap-0 {
  gap: 0 !important;
}
.gap-1 {
  gap: 0.1875rem !important;
}
.gap-2 {
  gap: 0.375rem !important;
}
.gap-3 {
  gap: 1.25rem !important;
}

.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.align-items-center {
  align-items: center !important;
}
.rounded-0{
  border-radius: 0!important;
}

.rounded-top {
  border-top-left-radius: $kendo-border-radius !important;
}
.rounded-end,
.rounded-top {
  border-top-right-radius: $kendo-border-radius !important;
}
.rounded-bottom,
.rounded-end {
  border-bottom-right-radius: $kendo-border-radius !important;
}
.rounded-start{
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.rounded-end{
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.rounded-bottom,
.rounded-start {
  border-bottom-left-radius: $kendo-border-radius !important;
}
.rounded-start {
  border-top-left-radius: $kendo-border-radius !important;
}
