@import 'colors.scss';
@import './variables.scss';

$btn-primary: $action-buttons;
$btn-primary-hover: #fff;
$btn-secondary: $blue;
$btn-tertiary: #3d3d3d;
$btn-danger: $red;
$btn-disabled: $gray6;
$btn-finish: #24d7f2;

.k-button-text {
  text-transform: initial;
}

.btn {
  cursor: pointer;
  font-weight: 400;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border-radius: $kendo-border-radius;
}

.k-button-text{
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  > * {
    display: flex;
  }
}

.icon-btn{
  .k-button-text {gap: 0.313rem};
}


.btn-primary {
  background-color: $btn-primary;
  color: $action-buttons-text;
  box-shadow: none;
  font-size: 0.75rem;
  padding: 8px;
  border: 1px solid $btn-primary;
  line-height: normal;
  &:before,&:hover{
    background: $btn-primary-hover;
    border-color:$btn-primary-hover;
  }
  &:disabled{
    opacity: 0.75;
    pointer-events: none;
    background-color: $btn-disabled;
    border-color: $btn-disabled;
    color: $action-buttons-text;
  }
  &:active {
    box-shadow: none;
    background-color: $white;
    color: $action-buttons-text;
  }
  .svg-icon path{
    fill: $action-buttons-text;
  }
}

.btn-secondary {
  background-color: $btn-secondary;
  border: 1px solid $btn-secondary;
  line-height: normal;
  box-shadow: none;
  font-size: 0.75rem;
  padding: 8px;
  color: $white;
  outline: none;
  &:focus,
  &:hover {
    background-color: $btn-secondary;
    border-color: $btn-secondary;
    color: $white;
    opacity: 0.75;
    box-shadow: none;
  }
  &:active{
    box-shadow: none;
  }
  &:disabled,&.disabled {
    opacity: 0.75;
    background-color: $btn-secondary;
    border-color: $btn-secondary;
    color: $white;
  }
  &:before{
    content: none;
  }
}

.btn-tertiary {
  background-color: $btn-tertiary;
  color: $white;
  box-shadow: none;
  font-size: 0.75rem;
  padding: 8px;
  border: 1px solid $btn-tertiary;
  line-height: normal;
  &:focus,
  &:hover {
    opacity: 0.75;
  }
  &:disabled{
    opacity: 0.75;
    pointer-events: none;
    background-color: $btn-disabled;
    border-color: $btn-disabled;
    color: $white;
  }
  .svg-icon path{
    fill: $white;
  }
}

.btn-finish{
  background-color: $btn-finish;
  border:1px solid $btn-finish;
  padding: 8px;
  color: $black;
  font-size: 0.875rem;
  .svg-icon path{
    fill: $action-buttons-text;
  }
  .svg-icon.svg-icon-sm{
    width: 1.5rem;
  }
  &:hover,&:focus{
    opacity: 0.75;
  }
}

.btn-save{
  color: $white;
  font-size: 0.875rem;
  .svg-icon.svg-icon-sm{
    width: 1.5rem;
  }
  &:disabled,&.disabled {
    background-color: $btn-secondary;
    border: 1px solid $btn-secondary;
    color: $btn-disabled;
  }
}

.btn-success {
  background-color: $success;
  border: 1px solid $success;
  line-height: normal;
  box-shadow: none;
  font-size: 0.75rem;
  padding: 8px;
  color: $white;
  outline: none;
  &:focus,
  &:hover {
    background-color: $success;
    border-color: $success;
    color: $white;
    opacity: 0.75;
    box-shadow: none;
  }
  &:active{
    box-shadow: none;
  }
  &:disabled,&.disabled {
    opacity: 0.75;
    background-color: $success;
    border-color: $success;
    color: $white;
  }
  &:before{
    content: none;
  }
}

.btn-danger {
  background-color: $btn-danger;
  border-color: $btn-danger;
  color: $white;
}

.btn-pure {
  background-color: transparent;
  color: $btn-primary-hover;
  box-shadow: none;
  font-size: 0.813rem;
  padding: 4px 8px;
  border: 1px solid $btn-tertiary;
  line-height: 22px;
  border-radius: 0.25rem;
  &:disabled{
    opacity: 0.75;
    pointer-events: none;
    background-color: $btn-disabled;
    border-color: $btn-disabled;
    color: $action-buttons-text;
  }
  &:active {
    box-shadow: none;
    background-color: $btn-tertiary;
    color: $btn-primary-hover;
  }
  &:focus {
    &:before{
      opacity: 0.22;
    }
}
  .svg-icon path{
    fill: $action-buttons-text;
  }
}


.close-btn{
  .svg-icon path{
    fill: $white;
  }
}

.k-button-lg{
  padding: 15px;
}