@import '../../themes/colors';

.tag {
  border-width: 1px;
  position: absolute !important;
  cursor: pointer;
  z-index: 999;
  border-left: 4px solid;
    .tag-label{
      position: absolute;
      top: -26px;
      left: -4px;
      border-left: 4px solid;
      line-height: normal;
      padding: 5px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      white-space: nowrap;
    }
    &:hover .tag-label,&.hover .tag-label{
      display: block;
    }
}

.tag-new-nav {
  mix-blend-mode: darken;
  border-color: $red;
  background: $red;
  opacity: 0.2;
  border-style: solid;
  border-width: 1px;
  position: absolute !important;
  cursor: pointer;
  z-index: 999;
}

.tag-new-nav.selected {
  opacity: 0.2;
}

.tag.selected {
  opacity: 0.5;
  z-index: 10000;
  background: $green-light2;
  border-color: $green-light2;
}

.selected {
  opacity: 0.5;
  z-index: 999;
  background: $green-light;
  border-color: $green-light;
}

.tag.error {
  border-color: $red1;
  border-width: 2px;
}

.tag .close {
  display: none;
}

.tag.closable .close {
  display: block;
  margin-right: 4px;
}

.tag.selected .ui-resizable-handle {
  opacity: 0.5;
}

.ui-resizable-handle {
  background-color: #333;
  border: 1px $white solid;
  font-size: 1px;
  height: 7px !important;
  width: 7px !important;
  opacity: 0;
  z-index: 10000;
}

.ui-resizable-handle.ui-resizable-n {
  left: 50%;
  margin-left: -4px;
  margin-top: -4px;
  top: 0;
}

.ui-resizable-handle.ui-resizable-s {
  bottom: 0;
  left: 50%;
  margin-bottom: -4px;
  margin-left: -4px;
}

.ui-resizable-handle.ui-resizable-e {
  margin-right: -4px;
  margin-top: -4px;
  right: 0;
  top: 50%;
}

.ui-resizable-handle.ui-resizable-w {
  left: 0;
  margin-left: -4px;
  margin-top: -4px;
  top: 50%;
}

.ui-resizable-handle.ui-resizable-nw {
  left: 0;
  margin-left: -4px;
  margin-top: -4px;
  top: 0;
}

.ui-resizable-handle.ui-resizable-ne {
  margin-right: -4px;
  margin-top: -4px;
  right: 0;
  top: 0;
}

.ui-resizable-handle.ui-resizable-se {
  bottom: 0;
  margin-bottom: -4px;
  margin-right: -4px;
  right: 0;
}

.ui-resizable-handle.ui-resizable-sw {
  bottom: 0;
  left: 0;
  margin-bottom: -4px;
  margin-left: -4px;
}

.smartHover {
  background: $green-light3;
  border-color: $green-light3;
  z-index: 1000;
  opacity: 0.1;
  cursor: default;
}
.smart-labels {
  .tag{
    .tag-label{
      display: none;
    }
    &:hover .tag-label,&.hover .tag-label{
      display: block;
    }
  }

}
