@import '../../../themes/colors';

.fields-block {
  position: relative !important;
}
.fields-block .finish-block {
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
  z-index: 3;
  width: 100%;
}

.split2 {
  left: 66px !important;
}

.active-insight {
  cursor: pointer;
  background-color: $green-light !important;
  color: $white !important;
}

.template-name-dropdown:hover {
  cursor: pointer;
  background-color: $green-light !important;
  color: $white !important;
}

.template-select-dropdown .dropdown-template-name {
  width: calc(100% - 84px);
}
.template-select-dropdown .dropdown-item h4 {
  width: calc(100% - 10px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dropdown-item-sub {
  font-weight: 400;
  display: block;
  clear: both;
  width: 100%;
  padding: 0.375rem 1.5rem;
  text-align: inherit;
  white-space: nowrap;
  color: $gray6;
  border: 0;
  background-color: transparent;
}

.dropdown-item-sub:hover {
  cursor: pointer;
  color: $white !important;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > a:after {
  content: '>';
  float: right;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: 0;
  margin-left: 0;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.split2 > .Pane.vertical.Pane1 {
  min-width: 406.5px;
  max-width: 44%;
}

.option-name-dropdown:hover {
  cursor: pointer;
  color: $white !important;
}

.col-doc-wrapper-select2 .select2-container .select2-search--inline {
  display: block !important;
}

.col-doc-wrapper-select2 .select2-search__field {
  color: $white;
  margin-left: 10px;
  margin-top: 0 !important;
}

.col-doc-wrapper-select2 .select2-container--default .select2-selection--single {
  border: 1px solid rgba(0, 0, 0, 0) !important;
}

.col-doc-wrapper-select2 .select2-container--default.select2-container--focus .select2-selection--single {
  border: 1px solid $blue !important;
}

#select2-templates-select-results li:first-child {
  background-color: $blue !important;
  border-radius: 0.25rem;
  text-align: center;
  color: $white !important;
}

.fields-viewport {
  margin-bottom: 0 !important;
  max-height: calc(100vh - 2 * 75px - 2 * 1px);
  min-height: calc(100vh - 2 * 75px - 2 * 1px);
  border-radius: 0.5rem;
}

.slider-viewport {
  padding: 0 0.75rem;
  margin-bottom: 0 !important;
  max-height: calc(100vh - 3 * 60px - 2 * 0.75rem - 2 * 1px);
  min-height: calc(100vh - 3 * 60px - 2 * 0.75rem - 2 * 1px);
  border-radius: 0.5rem;
}

.spinner-content {
  height: inherit;
}

.k-splitter .k-scrollable{
  overflow: hidden;
}
