@import '../../../src/themes/colors.scss';
.navbar-title{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navbar-light {
  background-color: $split-pane-bg;
  border-color: $split-pane-bg;
}
.navbar-light .navbar-heading {
  color: #c8cbcf;
}
.navbar-light .navbar-divider {
  background-color: #4d4e4f;
}
.navbar-light .navbar-user {
  border-top-color: #4d4e4f;
}
.navbar-light .navbar-user-link {
  color: #c8cbcf;
}
.navbar-light .navbar-user-link:focus,
.navbar-light .navbar-user-link:hover {
  color: #fff;
}
.navbar-light .navbar-brand {
  filter: none;
}
.navbar-light .navbar-collapse:before {
  border-top-color: #4d4e4f;
}
.navbar-user > a{
  color:$white;
}
