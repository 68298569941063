@import '../../../themes/colors.scss';

.selectbox {
  color: $white;

  &.form-control {
    height: inherit;
    padding: 0;
  }

  div {
    border: none;
    box-shadow: none;

    color: $white;
    &:focus {
      border: 0;
      outline: none;
    }

    .selectbox__indicators {
      & > span {
        display: none;
      }

      div:hover {
        color: $white;
      }

      svg {
        height: 17px;
        color: $gray6;
      }
    }
  }

  .selectbox__control {
    margin-top: -7px;
    padding: 0 5px;
  }

  div[class*='selectbox__'] {
    background: $gray1;
    left: 0;

    &.selectbox__value-container {
      padding-left: 0;
      padding-right: 0;
      border-radius: 0.375rem !important;
    }

    &.selectbox__menu {
      margin-top: 3px;
      box-shadow: 2px 6px 21px -2px $black;
      & > div:hover {
        background-color: #4d4e4f;

        div:not(.selectbox__option--is-disabled):hover {
          background-color: #2bb673;
        }
      }

      .selectbox__option--is-disabled {
        opacity: 0.75;
      }
    }

    .css-1rhbuit-multiValue,
    .selectbox__multi-value__label,
    .selectbox__multi-value__remove {
      background: $gray2;
      border-radius: 0.25rem !important;
    }

    .selectbox__multi-value__remove {
      margin-left: 0.5rem !important;
      color: $gray6 !important;
      order: 2 !important;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      margin-right: 2px;

      svg {
        height: 0.8125rem !important;
      }
    }
  }

  &.selectbox--is-disabled {
    .selectbox__indicators {
      display: none;
    }
  }

  &:focus {
    border: none;
  }
}
